import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  label: {
    margin: '5px 0',
    textAlign: 'left'
  },
  select: {
    borderRadius: '8px',
    width: '100%',
    padding: '16px 10px',
    fontSize: '18px',
    border: '1px solid #ccc',
    color: '#aaa',
    minWidth: '250px',
    background: 'transparent',
    '&:not(:checked)': {
      color: '#333'
    }
  }
});

export const Select = ({ label, options = [], name, onChange, defaultValue, value }) => {
  const classes = useStyles();
  const onchangeHandler = value => {
    onChange && onChange({
      [name]: value
    });
  };
  return (
    <div>
      <h3 className={classes.label}>{label}</h3>
      <select
        defaultValue={defaultValue || ''}
        value={value}
        onChange={e => onchangeHandler(e.target.value)}
        className={classes.select}
      >
        <option value="" disabled>尚未選擇</option>
        {
          options.map(item => {
            return (
              <option key={item.value} value={item.value}>{item.name}</option>
            );
          })
        }
      </select>
    </div >
  );
};