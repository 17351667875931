export const CATEGORY = {
  EDUCATIONAL: {
    LABEL: '學制',
    VALUE: 'educationalSystem'
  },
  COURSECATEGORY: {
    LABEL: '分類',
    VALUE: 'category'
  },
  COURSEFEATURES: {
    LABEL: '標籤',
    VALUE: 'hashtags'
  },
  TARGET: {
    LABEL: '產品',
    VALUE: 'target'
  }
};


export const ROLES = {
  Editor: '編輯',
};

export const TAG_CATEGORY = {
  EDUCATIONAL: {
    LABEL: '學制',
    VALUE: 'educationalSelection'
  },
  CATEGORY: {
    LABEL: '分類',
    VALUE: 'categorySelection'
  },
  HASHTAGS: {
    LABEL: '標籤',
    VALUE: 'hashtags'
  }
};

export const TARGET_OPTIONS = [
  {
    label: '學員見證',
    name: '學員見證',
    value: 'witness'
  },
  {
    label: '課程',
    name: '課程',
    value: 'course'
  }
];