import { useQuery, useMutation } from 'react-query';
import { getTags, createTag, updateTag, deleteTag } from 'api/tags';

const getTagsAction = async () => {
  const tags = await getTags();
  const educational = [];
  const category = [];
  const hashtags = [];

  tags.forEach(item => {
    item.value = item.id;
    item.name = item.label;
    if (item.category === 'educationalSystem') educational.push(item);
    if (item.category === 'category') category.push(item);
    if (item.category === 'hashtags') hashtags.push(item);
  });

  return {
    tags,
    educationalSelection: educational,
    categorySelection: category,
    hashtags: hashtags,
  };
};

export const useGetTags = () => {
  const { data: tagsData, refetch, isLoading } = useQuery('tags', getTagsAction);
  return [tagsData, refetch, isLoading];
};

export const useCreateTags = () => {
  const fetchCreateTags = async payload => await createTag(payload);
  const [postTags, { isLoading }] = useMutation(fetchCreateTags);
  return [postTags, isLoading];
};

const fetchUpdateTag = async payload => await updateTag(payload);

export const useUpdateTag = () => {
  const [putTag, { isLoading }] = useMutation(fetchUpdateTag);
  return [putTag, isLoading];
};

const fetchDeleteTag = async id => await deleteTag(id);

export const useDeleteTag = () => {
  const [deleteTag, { isLoading }] = useMutation(fetchDeleteTag);
  return [deleteTag, isLoading];
};
