import { useCallback, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import { useSetState } from 'utils/hooks/useSetState';

const envConfig = require('firebaseConfig.json');


const {
  COURSETUTOR_API_KEY,
  COURSETUTOR_AUTH_DOMAIN,
  COURSETUTOR_DATABASE_URL,
  COURSETUTOR_PROJECT_ID,
  COURSETUTOR_STORAGE_BUCKET
} = envConfig[process.env.REACT_APP_ENV];

const firebaseConfig = {
  apiKey: COURSETUTOR_API_KEY,
  authDomain: COURSETUTOR_AUTH_DOMAIN,
  databaseURL: COURSETUTOR_DATABASE_URL,
  projectId: COURSETUTOR_PROJECT_ID,
  storageBucket: COURSETUTOR_STORAGE_BUCKET
};
export const useFirebase = (config = firebaseConfig) => {
  const [{ storage }, setState] = useSetState({
    storage: null
  });
  const initialize = useCallback(() => {
    let app;
    if (firebase.apps.length <= 0 || !firebase.app('tutor-course')) {
      app = firebase.initializeApp(config, 'tutor-course');
    } else {
      app = firebase.app('tutor-course');
    }
    app.auth().signInAnonymously()
      .then(() => {
        setState({ storage: app.storage() });
      })
      .catch(err => console.error('sign in anonymously error', err));
    setState({ storage: app.storage() });
  }, [config, setState]);

  useEffect(() => {
    if (!config) return;
    initialize();
  }, [config, initialize]);
  return { storage };
};
