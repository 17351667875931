import api from 'api';

const TAGS = 'tags';

export const getTags = async () => {
  const { data } = await api.get(`/${TAGS}`);
  return data;
};

export const createTag = async payload => {
  const { data } = await api.post(`/admin/${TAGS}`, payload);
  return data;
};

export const updateTag = async payload => {
  const { data } = await api.put(`/admin/${TAGS}/${payload.id}`, payload);
  return data;
};

export const deleteTag = async id => {
  const { data } = await api.delete(`/admin/${TAGS}/${id}`);
  return data;
};