class Base64UploadAdapter {

  reader = new FileReader();

  constructor(loader) {
      this.loader = loader;
  }

  upload() {
     return new Promise((resolve, reject) => {
        const reader = this.reader;

        reader.onload = () => {
          resolve({ default: reader.result });
        };

        reader.onerror = err => {
          reject(err);
        };

        reader.onabort = () => {
          reject();
        };

        this.loader.file.then((file) => {
            reader.readAsDataURL(file);
        });
    });
  }
}

export default Base64UploadAdapter;