/* eslint-disable max-len */
import { StickyHeadTable, Input, Select, Modal, AutoComplete } from 'components';
import { useQuery } from 'react-query';
import { makeStyles } from '@material-ui/core/styles';
import { useSetState } from 'utils/hooks/useSetState';
import { convertArrayToMapById } from 'utils/array';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { TAG_CATEGORY, TARGET_OPTIONS, CATEGORY } from 'contants';
import { useModal } from 'utils/hooks/useModal';
import { useAlert } from 'utils/hooks/useAlert';
import { useGetTags, useCreateTags } from 'queries/useTags';
import { useDeleteTag } from 'queries/useTags';
import { useSnackbar } from 'notistack';

const tagSelections = [
  {
    name: TAG_CATEGORY.EDUCATIONAL.LABEL,
    value: TAG_CATEGORY.EDUCATIONAL.VALUE
  },
  {
    name: TAG_CATEGORY.CATEGORY.LABEL,
    value: TAG_CATEGORY.CATEGORY.VALUE
  },
  {
    name: TAG_CATEGORY.HASHTAGS.LABEL,
    value: TAG_CATEGORY.HASHTAGS.VALUE
  }
];

const tagTargetMap = {
  witness: {
    label: '學員見證'
  },
  course: {
    label: '課程'
  }
};

const useStyles = makeStyles({
  top: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: '1rem 0',
    flexWrap: 'wrap'
  },
  actionBox: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      marginRight: '1rem'
    }
  },
  buttonBox: {
    display: 'flex',
    width: '100%',
    marginBottom: '10px',
    '& > * + *': {
      marginLeft: '5px'
    }
  },
  modalContent: {
    '& > * + *': {
      marginTop: '10px'
    }
  },
  tag: {
    background: '#FFB122',
    color: '#fff',
    borderRadius: '5px',
    padding: '5px',
    margin: '5px',
    textShadow: '1px 1px 3px #ccc',
    transition: '0.35s',
    display: 'inline-block',
    '&:hover': {
      transform: 'rotate(15deg)'
    }
  }
});

const columns = [
  { id: 'nameLabel', label: '標籤名稱', align: 'center' },
  { id: 'targetLabel', label: '產品', align: 'center' },
  { id: 'sort', label: '順序', align: 'center' },
  { id: 'actions', label: '操作', align: 'center' },
];

const defaultChoose = 'hashtags';

export const Badge = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [postTags] = useCreateTags();
  const [tagsData, refetch] = useGetTags();
  const [deleteTag] = useDeleteTag();
  const { setAlert } = useAlert();
  const { isShowing, toggleModal } = useModal();
  const [{
    skip,
    limit,
    category,
    educationalSystem,
    courseFeatures,
    label,
    categoryChoose,
    isPublish,
    name,
    color,
    tagCategoryChoose,
    target
  }, setState] = useSetState({
    skip: 0,
    limit: 10,
    category: '',
    educationalSystem: '',
    courseFeatures: [],
    label: '',
    categoryChoose: '',
    isPublish: '',
    name: '',
    color: '',
    tagCategoryChoose: defaultChoose,
    badgeData: [],
    target: [],
  });

  const categoryLabel = (tagTarget) => {
    const tagTargetText = tagTarget.map(item => tagTargetMap[item]?.label).join('、');
    return tagTargetText;
  };

  const fetchBadge = () => {
    if (!tagsData || !tagCategoryChoose) return;
    
    const hashtagsData = tagsData[tagCategoryChoose].map(item => {
      return {
        ...item,
        nameLabel: item.name,
        targetLabel: categoryLabel(item.target.sort()),
        sort: item.sort,
        actions:
          <>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => deleteBadgeHandler(item.id)}
            >
              刪除
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push({ pathname: `/badge/editTag/${item.id}`, state: { id: item.id } })}
            >
              編輯
            </Button>
          </>
      };
    });
    return {
      hashtags: hashtagsData,
      hashtagsMap: convertArrayToMapById(hashtagsData),
      total: tagsData[tagCategoryChoose].length
    };
  };

  const {
    data: badgeData,
  } = useQuery([
    'badge',
    category,
    educationalSystem,
    courseFeatures.length,
    tagsData,
    isPublish,
    name,
    tagCategoryChoose,
  ], fetchBadge);

  const setPageHandler = (key, value) => {
    setState({
      [key]: value
    });
  };

  const onChangeHandler = value => {
    setState({
      ...value,
      skip: 0
    });
  };

  const onConfirm = async () => {
    if (!categoryChoose || !label || target.length === 0) return;
    await postTags({
      category: categoryChoose,
      label,
      color,
      target,
    });
    await refetch();
    enqueueSnackbar('新增成功');
    setState({
      categoryChoose: '',
      label: '',
      color: '',
      target: []
    });
  };

  const deleteBadgeHandler = id => {
    setAlert({
      html: '確定要刪除此標籤',
      confirmEvent: async () => {
        await deleteTag(id);
        enqueueSnackbar('刪除成功');
        await refetch();
      },
    });
  };

  return (
    <>
      <div className={classes.top}>
        <div className={classes.buttonBox}>
          <Button variant="contained" color="primary" onClick={() => toggleModal()}>新增分類</Button>
        </div>
        <div className={classes.actionBox}>
          {
            tagsData &&
            <Select
              defaultValue='hashtags'
              name="tagCategoryChoose"
              onChange={onChangeHandler}
              options={tagSelections}
              label="標籤選擇"
            />
          }
        </div>
      </div>
      { tagsData ?
        <StickyHeadTable
          data={badgeData?.hashtags || []}
          total={badgeData?.total || 0}
          columns={columns}
          limit={limit}
          skip={skip}
          setSkip={value => setPageHandler('skip', value)}
          setLimit={value => setPageHandler('limit', value)}
          hasPagination={false}
        /> : null
      }
      <Modal
        isShowing={isShowing}
        toggle={toggleModal}
        title="新增分類"
        confirm={() => onConfirm()}
      >
        <div className={classes.modalContent}>
          <Select
            name='categoryChoose'
            onChange={onChangeHandler}
            options={tagSelections}
            label="分類選擇"
            width="100%"
          />
          <AutoComplete
            label={CATEGORY.TARGET.LABEL}
            list={TARGET_OPTIONS || []}
            name={CATEGORY.TARGET.VALUE}
            onChange={onChangeHandler}
            multiple={true}
          />
          <Input name="label" label="標籤名稱" width="100%" placeholder="標籤名稱" onChange={onChangeHandler} />
          {
            categoryChoose === TAG_CATEGORY.CATEGORY.VALUE ?
              <Input type="color" name="color" label="顏色" width="100%" placeholder="顏色" onChange={onChangeHandler} />
              : null
          }

        </div>
      </Modal>
    </>
  );
};