import { useMutation, useQuery } from 'react-query';
import { createCourse, updateCourse, deleteCourse, getCourseById } from 'api/course';

const fetchCreateCourse = async payload => await createCourse(payload);

export const useCreateCourse = () => {
  const [postCourse, { isLoading }] = useMutation(fetchCreateCourse);
  return [postCourse, isLoading];
};


const fetchUpdateCourse = async (payload) => await updateCourse(payload);

export const useUpdateCourse = () => {
  const [putCourse, { isLoading }] = useMutation(fetchUpdateCourse);
  return [putCourse, isLoading];
};


const fetchDeleteCourse = async id => await deleteCourse(id);

export const useDeleteCourse = () => {
  const [deleteCourse, { isLoading }] = useMutation(fetchDeleteCourse);
  return [deleteCourse, isLoading];
};


const fetchGetCourseById = async id => {
  if (!id) return;
  return await getCourseById(id);
};

export const useGetCourseById = id => {
  const { data: course } = useQuery([id], fetchGetCourseById);
  return [course];
};

