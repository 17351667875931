import { AppLayout } from 'layouts';
import { Course, LoginPage, CourseForm, TagsForm, Witness, WitnessForm, Badge } from 'pages';
export const Routes = [
  {
    path: '/course',
    component: AppLayout,
    private: true,
    children: [
      {
        path: '/course',
        component: Course,
        exact: true
      },
      {
        path: '/course/create',
        component: CourseForm,
        exact: true,
      },
      {
        path: '/course/edit/:id',
        component: CourseForm,
        exact: true,
      },
    ]
  },
  {
    path: '/witness',
    component: AppLayout,
    private: true,
    children: [

      {
        path: '/witness',
        component: Witness,
        exact: true
      },
      {
        path: '/witness/create',
        component: WitnessForm,
        exact: true,
      },
      {
        path: '/witness/edit/:id',
        component: WitnessForm,
        exact: true,
      },
    ]
  },
  {
    path: '/badge',
    component: AppLayout,
    private: true,
    children: [

      {
        path: '/badge',
        component: Badge,
        exact: true
      },
      {
        path: '/badge/editTag/:id',
        component: TagsForm,
        exact: true,
      },
    ]
  },
  {
    path: '/',
    component: LoginPage
  }
];