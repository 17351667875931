import { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthContext from 'context/authContext';

const NotFound = () => <div>Not Found</div>;

const RenderRoutes = ({ routes = [] }) => {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Switch>
      {
        routes.map(route => (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            render={props => {
              if (route.private) {
                if (isAuthenticated) {
                  return <route.component {...props} {...route} routes={route.children} />;
                } else {
                  return <Redirect to="/" />;
                }
              } else {
                return <route.component {...props} {...route} routes={route.children} />;
              }
            }}
          />
        ))
      }
      <Route component={NotFound} />
    </Switch>
  );
};

export default RenderRoutes;