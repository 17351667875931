import { useSetState } from 'utils/hooks/useSetState';

export const useModal = () => {
  const [{
    isShowing,
  }, setState] = useSetState({
    isShowing: false,
  });

  const toggleModal = () => {
    setState({
      isShowing: !isShowing,
    });
  };
  return { isShowing, toggleModal };
};