import { useState, useEffect, createContext } from 'react';
import { checkNaniOneClass, getProfile, logoutNaniOneClass } from 'services/oneClub';
import { ROLES } from 'contants';

const AuthContext = createContext({
  isAuthenticated: true
});

export const AuthProvider = ({ children }) => {

  const [isAuthenticated, setAuthenticated] = useState(true);

  useEffect(() => {
    const checkLogin = async () => {
      await checkNaniOneClass(async token => {
        if (token) {
          const { identity } = await getProfile({ jwt: token });
          if (identity === ROLES.Editor) {
            setAuthenticated(true);
          } else {
            logoutNaniOneClass();
          }
        } else {
          setAuthenticated(false);
        }
      });
    };
    checkLogin();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};


export default AuthContext;