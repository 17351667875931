import React from 'react';
import RenderRoutes from 'router/RenderRoutes';
import { makeStyles } from '@material-ui/core/styles';
import { Navigator } from 'components';
import { Header } from 'containers';


/**
 * App layout
 */

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    padding: theme.spacing(4, 4),
    background: '#eaeff1',
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  footer: {
    padding: theme.spacing(2),
    background: '#eaeff1',
  },
}));

const drawerWidth = 256;

export const AppLayout = ({ routes }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <nav className={classes.drawer}>
        <Navigator drawerWidth={drawerWidth} />
      </nav>
      <div className={classes.app}>
        <Header />
        <main className={classes.main}>
          {/* <Content /> */}
          <RenderRoutes routes={routes} />
        </main>
      </div>
    </div>
  );
};

