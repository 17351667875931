/* eslint-disable no-console */
import axios from 'axios';
import { checkNaniLinkLogin } from 'services/oneClub';

axios.default.timeout = 20000;

const parseEndpoint = (endpoint, params) => {
  const url = endpoint.indexOf('http') === 0 ? endpoint : process.env.REACT_APP_API_DOMAIN + endpoint;
  return url;
};


const formatParams = data => {
  Object.entries(data).forEach(([key, value]) => {
    if (value === '' || value.length === 0) {
      delete data[key];
    }
  });
  return data;
};

axios.interceptors.request.use(
  config => {
    config.data = JSON.stringify(config.data);
    config.headers = {
      'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
      'Authorization': JSON.parse(checkNaniLinkLogin()).jwt || null,
      'Content-Type': 'application/json'
    };
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  err => {
    if (err && err.response) {
      switch (err.response.status) {
        case 404:
          console.log('沒有這個拉！');
          break;
        case 500:
          console.log('伺服器錯了哦！');
          break;
        case 503:
          console.log('不想服務拉！');
          break;
        default:
          console.log(`連結錯誤${err.response.status}`);
          break;
      }
    } else {
      console.log('連結錯誤');
    }
    return Promise.resolve(err.response);
  }
);


export const fetch = (url, params = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: formatParams(params)
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const post = (url, data = {}) => {
  return new Promise((resolve, reject) => {
    axios.post(url, formatParams(data)).then(
      response => {
        resolve(response.data);
      },
      err => {
        reject(err);
      }
    );
  });
};

export const remove = (url, data = {}) => {
  return new Promise((resolve, reject) => {
    axios.delete(url, formatParams(data)).then(
      response => {
        resolve(response.data);
      },
      err => {
        reject(err);
      }
    );
  });
};

export const put = (url, data = {}) => {
  return new Promise((resolve, reject) => {
    axios.put(url, data).then(
      response => {
        resolve(response.data);
      },
      err => {
        reject(err);
      }
    );
  });
};

const api = {};

api.create = () => ({
  get: (url, params) => fetch(parseEndpoint(url), params),
  post: (url, data) => post(parseEndpoint(url), data),
  put: (url, data) => put(parseEndpoint(url), data),
  delete: (url, params) => remove(parseEndpoint(url), params),
});

export const Api = api.create();

export default Api;
