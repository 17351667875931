/* eslint-disable max-len */
import { StickyHeadTable, AutoComplete, Input, Select, Modal } from 'components';
import { usePaginatedQuery } from 'react-query';
import { getCourse } from 'api/course';
import { makeStyles } from '@material-ui/core/styles';
import { useSetState } from 'utils/hooks/useSetState';
import { convertArrayToMapById } from 'utils/array';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { CATEGORY } from 'contants';
import { useModal } from 'utils/hooks/useModal';
import { useAlert } from 'utils/hooks/useAlert';
import { useGetTags, useCreateTags } from 'queries/useTags';
import { useDeleteCourse } from 'queries/useCourse';
import { useSnackbar } from 'notistack';


const tagSelections = [
  {
    name: CATEGORY.EDUCATIONAL.LABEL,
    value: CATEGORY.EDUCATIONAL.VALUE
  },
  {
    name: CATEGORY.COURSECATEGORY.LABEL,
    value: CATEGORY.COURSECATEGORY.VALUE
  },
  {
    name: CATEGORY.COURSEFEATURES.LABEL,
    value: CATEGORY.COURSEFEATURES.VALUE
  }
];

const useStyles = makeStyles({
  top: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: '1rem 0',
    flexWrap: 'wrap'
  },
  actionBox: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      marginRight: '1rem'
    }
  },
  buttonBox: {
    display: 'flex',
    width: '100%',
    marginBottom: '10px',
    '& > * + *': {
      marginLeft: '5px'
    }
  },
  modalContent: {
    '& > * + *': {
      marginTop: '10px'
    }
  },
  tag: {
    background: '#FFB122',
    color: '#fff',
    borderRadius: '5px',
    padding: '5px',
    margin: '5px',
    textShadow: '1px 1px 3px #ccc',
    transition: '0.35s',
    display: 'inline-block',
    '&:hover': {
      transform: 'rotate(15deg)'
    }
  }
});

const columns = [
  { id: 'nameLabel', label: '課程名稱', align: 'center' },
  { id: 'image', label: '縮圖', minWidth: 100, align: 'center' },
  { id: 'educationalSystemLabel', label: CATEGORY.EDUCATIONAL.LABEL, align: 'center' },
  { id: 'categoryLabel', label: CATEGORY.COURSECATEGORY.LABEL, align: 'center' },
  { id: 'hashtagsLabel', label: CATEGORY.COURSEFEATURES.LABEL, align: 'center' },
  { id: 'status', label: '狀態', align: 'center' },
  { id: 'actions', label: '操作', align: 'center' },
];

const statusOptions = [
  {
    name: '啟用',
    value: true
  },
  {
    name: '停用',
    value: false
  },
];

export const Course = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [postTags] = useCreateTags();
  const [tagsData, refetch] = useGetTags();
  const [deleteCourse] = useDeleteCourse();
  const { setAlert } = useAlert();
  const { isShowing, toggleModal } = useModal();
  const [{
    skip,
    limit,
    category,
    educationalSystem,
    courseFeatures,
    label,
    categoryChoose,
    isPublish,
    name,
    color,
    hashtags
  }, setState] = useSetState({
    skip: 0,
    limit: 10,
    category: '',
    educationalSystem: '',
    courseFeatures: [],
    label: '',
    categoryChoose: '',
    isPublish: '',
    name: '',
    color: '',
    hashtags: []
  });

  const filterTagType = (data) => {
    return data.filter(item => item.target.includes('course'));
  };

  const fetchCourse = async () => {
    if (!tagsData) return;
    const params = {
      skip: skip * limit,
      limit,
      category,
      educationalSystem,
      courseFeatures,
      isPublish,
      hashtags,
      name
    };

    const res = await getCourse(params);
    const mapCategorySelection = convertArrayToMapById(filterTagType(tagsData.categorySelection));
    const mapEducationalSelection = convertArrayToMapById(filterTagType(tagsData.educationalSelection));
    const mapHashtagsSelection = convertArrayToMapById(filterTagType(tagsData.hashtags));
    const courseData = res.courses.map(item => {
      return {
        ...item,
        nameLabel: item.isRecommend ?
          <><span className={classes.tag}>推薦!</span>{item.name}</> : item.name,
        categoryLabel: mapCategorySelection[item.category]?.label || '-',
        educationalSystemLabel: mapEducationalSelection[item.educationalSystem]?.label || '通用',
        hashtagsLabel: item.hashtags.filter(item => mapHashtagsSelection[item]?.label).map(item => mapHashtagsSelection[item]?.label).join(',')|| '-',
        status: item.isPublish ? '啟用' : '停用',
        image: item.thumbnail ? <img src={item.thumbnail} alt='' /> : '',
        actions:
          <>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => deleteCourseHandler(item.id)}
            >
              刪除
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push({ pathname: `/course/edit/${item.id}`, state: { id: item.id } })}
            >
              編輯
            </Button>
          </>
      };
    });

    return {
      courses: courseData,
      coursesMap: convertArrayToMapById(courseData),
      total: res.total
    };
  };

  const {
    latestData,
    refetch: courseRefetch
  } = usePaginatedQuery([
    'course',
    skip,
    limit,
    category,
    educationalSystem,
    courseFeatures.length,
    hashtags,
    tagsData,
    isPublish,
    name
  ], fetchCourse);

  const setPageHandler = (key, value) => {
    setState({
      [key]: value
    });
  };

  const onChangeHandler = value => {
    setState({
      ...value,
      skip: 0
    });
  };

  const onConfirm = async () => {
    if (!categoryChoose || !label) return;
    await postTags({
      category: categoryChoose,
      label,
      color,
      target: ['course'],
    });
    enqueueSnackbar('新增成功');
    await refetch();
    setState({
      categoryChoose: '',
      label: '',
      color: ''
    });
  };

  const deleteCourseHandler = id => {
    setAlert({
      html: '確定要刪除此課程',
      confirmEvent: async () => {
        await deleteCourse(id);
        enqueueSnackbar('刪除成功');
        await courseRefetch();
      },
    });
  };

  return (
    <>
      <div className={classes.top}>
        <div className={classes.buttonBox}>
          <Button variant="contained" color="secondary" onClick={() => history.push('/course/create')}>新增課程</Button>
        </div>
        <div className={classes.actionBox}>
          {
            tagsData &&
            <>
              <Input value={name} name="name" label="課程名稱" width="100%" placeholder='課程名稱' onChange={onChangeHandler} />
              <AutoComplete
                label={CATEGORY.EDUCATIONAL.LABEL}
                list={filterTagType(tagsData?.educationalSelection) || []}
                name={CATEGORY.EDUCATIONAL.VALUE}
                onChange={onChangeHandler}
              />
              <AutoComplete
                label={CATEGORY.COURSECATEGORY.LABEL}
                list={filterTagType(tagsData?.categorySelection) || []}
                name={CATEGORY.COURSECATEGORY.VALUE}
                onChange={onChangeHandler}
              />
              <AutoComplete
                label={CATEGORY.COURSEFEATURES.LABEL}
                list={filterTagType(tagsData?.hashtags) || []}
                name={CATEGORY.COURSEFEATURES.VALUE}
                onChange={onChangeHandler}
                multiple={true}
              />
              <AutoComplete
                name="isPublish"
                onChange={onChangeHandler}
                list={statusOptions}
                label="狀態"
              />
            </>
          }
        </div>
      </div>
      { tagsData ?
        <StickyHeadTable
          data={latestData?.courses || []}
          total={latestData?.total || 0}
          columns={columns}
          limit={limit}
          skip={skip}
          setSkip={value => setPageHandler('skip', value)}
          setLimit={value => setPageHandler('limit', value)}
        /> : null
      }
      <Modal
        isShowing={isShowing}
        toggle={toggleModal}
        title="新增分類"
        confirm={() => onConfirm()}
      >
        <div className={classes.modalContent}>
          <Select
            name="categoryChoose"
            onChange={onChangeHandler}
            options={tagSelections}
            label="分類選擇"
            width="100%"
          />
          <Input name="label" label="標籤名稱" width="100%" placeholder="標籤名稱" onChange={onChangeHandler} />
          {
            categoryChoose === 'category' ?
              <Input type="color" name="color" label="顏色" width="100%" placeholder="顏色" onChange={onChangeHandler} />
              : null
          }
        </div>
      </Modal>
    </>
  );
};