import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  TextField,
  Button,
} from '@material-ui/core';
import {
  sendData as login,
  getProfile,
  logoutNaniOneClass
} from 'services/oneClub';
import { ROLES } from 'contants';
import AuthContext from 'context/authContext';
import { useSnackbar } from 'notistack';

export const LoginForm = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { setAuthenticated } = useContext(AuthContext);


  const [account, setAccount] = useState('');
  const [password, setPassword] = useState('');

  const handlerAccountChange = event => {
    setAccount(event.target.value);
  };

  const handlerPasswordChange = event => {
    setPassword(event.target.value);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const result = await login(account, password);
    if (result.code !== 'SUCCESS') {
      alert(result.message);
      setPassword('');
      setAuthenticated(false);
      return;
    }
    const { identity } = await getProfile({ jwt: JSON.parse(result.jwt).jwt });
    if (identity === ROLES.Editor) {
      setAuthenticated(true);
      enqueueSnackbar('登入成功！');
      history.push('/course');
    } else {
      enqueueSnackbar('登入帳號權限不足！');
      logoutNaniOneClass();
    }
  };



  return (
    <form onSubmit={handleSubmit}>
      <TextField
        fullWidth
        label="帳號"
        margin="normal"
        name="account"
        variant="outlined"
        value={account}
        onChange={handlerAccountChange}
      />
      <TextField
        fullWidth
        label="密碼"
        margin="normal"
        name="password"
        type="password"
        variant="outlined"
        value={password}
        onChange={handlerPasswordChange}
      />
      <Button
        color="primary"
        fullWidth
        size="large"
        type="submit"
        variant="contained"
      >
        登入
      </Button>
    </form>
  );
};