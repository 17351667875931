import { useMutation, useQuery } from 'react-query';
import { createWitness, updateWitness, deleteWitness, getWitnessById } from 'api/witness';

const fetchCreateWitness = async payload => await createWitness(payload);

export const useCreateWitness = () => {
  const [postWitness, { isLoading }] = useMutation(fetchCreateWitness);
  return [postWitness, isLoading];
};


const fetchUpdateWitness = async (payload) => await updateWitness(payload);

export const useUpdateWitness = () => {
  const [putWitness, { isLoading }] = useMutation(fetchUpdateWitness);
  return [putWitness, isLoading];
};


const fetchDeleteWitness = async id => await deleteWitness(id);

export const useDeleteWitness = () => {
  const [deleteWitness, { isLoading }] = useMutation(fetchDeleteWitness);
  return [deleteWitness, isLoading];
};


const fetchGetWitnessById = async id => {
  if (!id) return;
  return await getWitnessById(id);
};

export const useGetWitnessById = id => {
  const { data: Witness } = useQuery([id], fetchGetWitnessById);
  return [Witness];
};

