import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { Routes } from 'router';
import RenderRoutes from 'router/RenderRoutes';
import theme from 'theme';
import { AuthProvider } from 'context/authContext';
import { SnackbarProvider } from 'notistack';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 86400000
    },
  },
});


function App() {
  return (
    <ReactQueryCacheProvider qyeryCache={queryCache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ReactQueryDevtools />
        <BrowserRouter>
          <SnackbarProvider
            maxSnack={5}
            autoHideDuration={2000}
            anchorOrigin={
              {
                vertical: 'top',
                horizontal: 'right'
              }
            }
          >
            <AuthProvider>
              <RenderRoutes routes={Routes} />
            </AuthProvider>
          </SnackbarProvider>
        </BrowserRouter>
      </ThemeProvider>
    </ReactQueryCacheProvider>
  );
}

export default App;
