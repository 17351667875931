import api from 'api';

export const getWitness = async params => {
  const { data } = await api.get('/witness', params);
  return data;
};

export const createWitness = async payload => {
  const { data } = await api.post('/admin/witness', payload);
  return data;
};

export const updateWitness = async (payload) => {
  const { data } = await api.put(`/admin/witness/${payload.id}`, payload);
  return data;
};

export const getWitnessById = async id => {
  const { data } = await api.get(`/witness/${id}`);
  return data;
};

export const deleteWitness = async id => {
  const { data } = await api.delete(`/admin/witness/${id}`);
  return data;
};