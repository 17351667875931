import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import { Class as ClassIcon, Loyalty as LoyaltyIcon, Face as FaceIcon } from '@material-ui/icons';

const categories = [
  {
    id: '標籤',
    children: [
      { id: '標籤總覽', icon: <LoyaltyIcon />, link: '/badge' },
    ],
  },{
    id: '課程',
    children: [
      { id: '課程總覽', icon: <ClassIcon />, link: '/course' },
    ],
  },
  {
    id: '學員見證',
    children: [
      { id: '學員見證總覽', icon: <FaceIcon />, link: '/witness' },
    ],
  },
];

const useStyles = makeStyles((theme) => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemCategory: {
    backgroundColor: '#232f3e',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    cursor: 'pointer',
  },
  firebase: {
    fontSize: 24,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: '#4fc3f7',
  },
  itemPrimary: {
    fontSize: 'inherit',
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
}));

export const Navigator = ({
  drawerWidth
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  return (
    <Drawer variant="permanent" PaperProps={{ style: { width: drawerWidth } }}>
      <List disablePadding>
        <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
          oneClass課程後台
        </ListItem>
        <ListItem className={clsx(classes.item, classes.itemCategory)} onClick={() => history.push('/')}>
          <ListItemIcon className={classes.itemIcon}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
            }}
          >
            首頁
          </ListItemText>
        </ListItem>
        {categories.map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, link }) => (
              <ListItem
                key={childId}
                button
                className={clsx(classes.item, link === pathname && classes.itemActiveItem)}
                onClick={() => history.push(link)}
              >
                <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                >
                  {childId}
                </ListItemText>
              </ListItem>
            ))}

            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
};