import PropTypes from 'prop-types';
import { TextField as MatTextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';


/**
 * 可以搜尋的下拉選單
 */
const noop = () => { };
const useStyles = makeStyles({
  root: {
    minWidth: '250px',
    maxWidth: ({ width }) => width,
    '& > h3': {
      margin: '3px 0 0'
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: '9px'
    }
  },
});

export const AutoComplete = ({
  list = [],
  label = '別亂開支票啊',
  onChange = noop,
  name = 'defaultName',
  width = 'auto',
  multiple = false,
  value,
  loading = false
}) => {
  const classes = useStyles({ width });

  const onChangeHandler = (e, selected) => {
    onChange({ [name]: multiple ? selected.map(item => item.value) : (selected?.value || '') });
  };
  return (
    <div className={classes.root}>
      <h3>{label}</h3>
      <Autocomplete
        multiple={multiple}
        options={list}
        onChange={onChangeHandler}
        getOptionLabel={option => (option.name ? option.name : '')}
        filterSelectedOptions
        renderInput={params => <MatTextField {...params} variant="outlined" />}
        value={value}
        loading={loading}
      />
    </div>
  );
};

AutoComplete.propTypes = {
  list: PropTypes.array,
  label: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
};
