import { useEffect } from 'react';
import lrz from 'lrz';
import { v4 as uuidv4 } from 'uuid';
import { Input, AutoComplete, UploadButton } from 'components';
import { useSetState } from 'utils/hooks/useSetState';
import { Button, Switch, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CATEGORY } from 'contants';
import { useFirebaseStorage } from 'utils/hooks/useFirebaseStorage';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useCreateCourse, useUpdateCourse, useGetCourseById } from 'queries/useCourse';
import { useSnackbar } from 'notistack';
import { useGetTags } from 'queries/useTags';
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@yanjai/ckeditor5-build-decoupled-document';
import Base64UploadAdapter from 'services/CKEditor/base64UploadAdapter';



const useStyles = makeStyles({
  inputBox: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: '10px 0',
    borderRadius: '5px',
    overFlow: 'hidden',
    '& > *': {
      marginRight: '10px'
    }
  },
  editorBox: {
    width: '100%',
    border: '1px solid #ccc',
    borderRadius: '5px',
    margin: '10px 0',
    '& .ck-editor__editable': {
      minHeight: '250px',
      minWidth: '1000px'
    }
  },
  buttonBox: {
    display: 'flex',
    '& > *': {
      marginRight: '5px'
    }
  },
  preview: {
    height: ({ preview }) => preview ? '900px' : 0,
    overflowX: 'hidden',
    margin: '20px auto',
    transition: '0.35s'
  }
});

export const CourseForm = () => {
  const [{
    name,
    category,
    educationalSystem,
    hashtags,
    file,
    imageSrc,
    content,
    isPublish,
    isLoading,
    isRecommend,
    preview
  }, setState] = useSetState({
    name: '',
    category: '',
    educationalSystem: '',
    hashtags: [],
    file: null,
    imageSrc: '',
    content: '',
    isPublish: false,
    isLoading: false,
    isRecommend: false,
    preview: false
  });
  const classes = useStyles({ preview });
  const history = useHistory();
  const { id } = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [postCourse] = useCreateCourse();
  const [putCourse] = useUpdateCourse();
  const {
    upload,
  } = useFirebaseStorage();
  const [tagsData, , loading] = useGetTags();
  const [course] = useGetCourseById(state?.id || id || '');

  const onChangeHandler = value => {
    setState({
      ...value
    });
  };

  const formatMultipleDefaultValue = values => {
    const nextValues = [];
    tagsData.hashtags.forEach(course => {
      values.forEach(item => {
        if (course.id === item) nextValues.push(course);
      });
    });
    return nextValues;
  };


  const onsubmit = async () => {
    if (
      !educationalSystem ||
      !category ||
      !name ||
      (!file && !imageSrc) ||
      !content
    ) {
      enqueueSnackbar('尚有欄位未填寫！');
      return;
    }
    if (hashtags.length < 2) {
      enqueueSnackbar('課程標籤至少選擇兩個！');
      return;
    }
    setState({
      isLoading: true
    });
    let url = '';
    if (file) {
      const uploadPath = `course/${uuidv4()}.png`;
      const res = await upload(uploadPath, file);
      url = res.url;
      enqueueSnackbar('上傳圖片成功！');
    }
    const payload = {
      name,
      content,
      category,
      educationalSystem,
      hashtags,
      thumbnail: file === null ? imageSrc : url,
      isRecommend
    };
    if (id) {
      payload.isPublish = isPublish;
      await putCourse({ id, ...payload });
    } else {
      await postCourse(payload);
    }
    enqueueSnackbar(id ? '更新成功' : '新增成功！');
    setState({
      isLoading: false
    });
    history.push('/course');
  };

  const onFileChange = async file => {
    lrz(file[0]).then(res => {
      setState({
        imageSrc: res.base64
      });
      for (let value of res.formData.values()) {
        setState({
          file: value
        });
      }
    });
  };

  const onChange = (event, editor) => {
    const data = editor.getData();
    setState({
      content: data
    });
  };

  const filterTagType = (data) => {
    return data.filter(item => item.target.includes('course'));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!course || !tagsData) return;
    setState({
      name: course.name || '',
      category: course.category,
      educationalSystem: course.educationalSystem,
      hashtags: course.hashtags || [],
      file: null,
      imageSrc: course.thumbnail || '',
      content: course.content || '',
      isPublish: course.isPublish || false,
      isLoading: false,
      isRecommend: course.isRecommend || false
    });
  }, [course, tagsData]);
  return (
    <div>
      <h1>{id ? '編輯課程' : '新增課程'}</h1>
      {id ?
        <FormControlLabel
          control={
            <Switch
              checked={isPublish}
              onChange={e => onChangeHandler({ isPublish: e.target.checked })}
            />
          }
          label="是否上架"
        /> : null
      }
      <FormControlLabel
        control={
          <Switch
            checked={isRecommend}
            onChange={e => onChangeHandler({ isRecommend: e.target.checked })}
          />
        }
        label="是否為推薦課程"
      />
      <div className={classes.inputBox}>
        <Input
          label="課程名稱"
          placeholder="輸入課程名稱"
          onChange={onChangeHandler}
          name="name"
          value={name}
          width="100%"
        />
        {
          tagsData ?
            <>
              <AutoComplete
                label={CATEGORY.EDUCATIONAL.LABEL}
                list={filterTagType(tagsData?.educationalSelection) || []}
                name="educationalSystem"
                onChange={onChangeHandler}
                width="194px"
                value={tagsData?.educationalSelection.filter(item => item.value === educationalSystem)[0] || {}}
                loading={loading}
              />
              <AutoComplete
                label={CATEGORY.COURSECATEGORY.LABEL}
                list={filterTagType(tagsData?.categorySelection) || []}
                name="category"
                onChange={onChangeHandler}
                width="194px"
                value={tagsData?.categorySelection.filter(item => item.value === category)[0] || {}}
                loading={loading}
              />
              <AutoComplete
                label={CATEGORY.COURSEFEATURES.LABEL}
                list={filterTagType(tagsData?.hashtags) || []}
                name="hashtags"
                onChange={onChangeHandler}
                multiple={true}
                value={formatMultipleDefaultValue(hashtags)}
                loading={loading}
              />
            </> : null
        }

      </div>
      <UploadButton onChange={onFileChange} src={imageSrc} />
      <div className={classes.editorBox}>
        <CKEditor
          config={{
            fontSize: {
              options: [12, 14, 16, 18, 'default', 24, 28, 32],
            },
          }}
          onInit={(editor) => {
            editor.ui.getEditableElement().parentElement.insertBefore(
              editor.ui.view.toolbar.element,
              editor.ui.getEditableElement()
            );
            editor.plugins.get('FileRepository').createUploadAdapter = loader => {
              return new Base64UploadAdapter(loader);
            };

            if (content) editor.data.set(content);
          }}
          editor={DecoupledEditor}
          onChange={onChange}
          data={content || ''}
        />
      </div>
      <div className={classes.buttonBox}>
        <Button
          variant="contained"
          disabled={isLoading}
          color="secondary"
          href="/course"
        >
          取消
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={isLoading}
          onClick={() => onsubmit()}
        >
          送出
        </Button>
        {
          id ?
            <Button
              variant="contained"
              color="inherit"
              onClick={() => history.push({ pathname: '/course/create', state: { id } })}
              disabled={isLoading}
            >
              複製
            </Button> : null
        }
        <Button
          variant="contained"
          disabled={isLoading}
          onClick={() => setState({ preview: !preview })}
        >
          預覽
        </Button>
      </div>
      <div className={`ck-content ${classes.preview}`} dangerouslySetInnerHTML={{ __html: content }}></div>
    </div>
  );
};