import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
} from '@material-ui/core';
import { LoginForm } from 'containers';
import AuthContext from 'context/authContext';
// import { checkNaniOneClass } from 'services/oneClub';

export const LoginPage = () => {
  const history = useHistory();
  const { isAuthenticated } = useContext(AuthContext);
  useEffect(() => {
    isAuthenticated && history.push('/course');
  }, [isAuthenticated]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100vh"
      justifyContent="center"
    >
      <Container maxWidth="sm">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box textAlign="center">
            <Typography
              color="textSecondary"
              variant="h3"
            >
              Tutor 後台
            </Typography>
            <LoginForm />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};