import { useEffect } from 'react';
import { useGetTags, useUpdateTag, useDeleteTag } from 'queries/useTags';
import { CATEGORY, TARGET_OPTIONS } from 'contants';
import { Input, Select, AutoComplete } from 'components';
import { useSetState } from 'utils/hooks/useSetState';
import { Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '30px 0 60px',
  },
  formBox: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > *': {
      marginRight: '10px'
    }
  },
  buttonBox: {
    margin: '20px 0',
    '& > *': {
      marginRight: '10px'
    }
  }
}));


const tagSelections = [
  {
    name: CATEGORY.EDUCATIONAL.LABEL,
    value: CATEGORY.EDUCATIONAL.VALUE
  },
  {
    name: CATEGORY.COURSECATEGORY.LABEL,
    value: CATEGORY.COURSECATEGORY.VALUE
  },
  {
    name: CATEGORY.COURSEFEATURES.LABEL,
    value: CATEGORY.COURSEFEATURES.VALUE
  }
];


export const TagsForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [tagsData] = useGetTags();
  const [putTag] = useUpdateTag();
  const [deleteTag] = useDeleteTag();
  const [{
    categoryChoose,
    categoryId,
    category,
    label,
    sort,
    color,
    target,
  }, setState] = useSetState({
    categoryChoose: '',
    categoryId: id,
    category: '',
    label: '',
    sort: 0,
    color: '',
    target: [],
  });

  const onChangeHandler = value => {
    setState({
      ...value,
    });
  };

  const onSubmit = async () => {
    const payload = {
      category,
      label,
      sort: parseInt(sort),
      target
    };
    if (color) payload.color = color;
    await putTag({ id: categoryId, ...payload });
    enqueueSnackbar('更新成功！');
    history.goBack();
  };

  const onDelete = async () => {
    await deleteTag(categoryId);
    enqueueSnackbar('刪除成功！');
    history.goBack();
  };

  const formatMultipleDefaultValue = values => {
    const nextValues = [];
    TARGET_OPTIONS.forEach(target => {
      values.forEach(item => {
        if (target.value === item) nextValues.push(target);
      });
    });

    return nextValues;
  };

  useEffect(() => {
    if (!tagsData || categoryId === '') return;
    const { category, label, sort, color = '', target } = tagsData.tags.filter(item => item.id === categoryId)[0];
    setState({
      category,
      label,
      sort,
      color,
      target
    });
  }, [categoryId]);

  useEffect(() => {
    setState({
      categoryChoose: category
    });
  }, [category]);

  return (
    <div>
      {
        categoryId ?
          <>
            <Divider className={classes.root} />
            <h1>編輯標籤</h1>
            <div className={classes.formBox}>
              <Select
                name="category"
                value={category}
                onChange={() => onChangeHandler()}
                options={tagSelections}
                label="分類類型"
                width="100%"
              />
              <Input value={label} label="標籤名稱" placeholder="標籤名稱" name="label" onChange={onChangeHandler} />
              <AutoComplete
                label={CATEGORY.TARGET.LABEL}
                list={TARGET_OPTIONS || []}
                name={CATEGORY.TARGET.VALUE}
                onChange={onChangeHandler}
                multiple={true}
                value={formatMultipleDefaultValue(target)}
              />

              <Input value={sort} label="排序" placeholder="排序" type="number" name="sort" onChange={onChangeHandler} />
              {
                categoryChoose === CATEGORY.COURSECATEGORY.VALUE ?
                  <Input
                    type="color"
                    name="color"
                    value={color}
                    label="顏色"
                    width="250px"
                    placeholder="顏色"
                    onChange={onChangeHandler}
                  />
                  : null
              }
            </div>
            <div className={classes.buttonBox}>
              <Button variant="contained" color="secondary" onClick={() => history.goBack()}>取消</Button>
              <Button variant="contained" color="primary" onClick={() => onSubmit()}>送出</Button>
              <Button variant="contained" onClick={() => onDelete()}>刪除</Button>
            </div>
          </>
          : null
      }
    </div>
  );
};