import { useCallback } from 'react';
import { useFirebase } from 'utils/hooks/useFirebase';

export const useFirebaseStorage = () => {
  const { storage } = useFirebase();
  const storgeRef = useCallback(() => {
    return storage.ref();
  }, [storage]);

  const upload = async (uploadPath, file) => {
    try {
      const snapshot = await storgeRef().child(uploadPath).put(file);
      const url = await storgeRef().child(uploadPath).getDownloadURL();
      return {
        status: true,
        data: snapshot,
        url
      };
    } catch (error) {
      return {
        status: false,
        data: error
      };
    }
  };

  const getDownloadURL = async path => {
    try {
      const url = await storgeRef().child(path).getDownloadURL();
      return {
        status: 'success',
        url
      };
    } catch (error) {
      alert('檔案不存在');
      return {
        status: 'fail',
      };
    }
  };

  const deleteFile = async filePath => {
    const res = await storgeRef().child(filePath).delete().then(() => {
      return { isSuccess: true };
    }).catch(error => {
      return { isSuccess: false, error };
    });
    return res;
  };

  const getFileList = async filePath => {
    const res = await storgeRef().child(filePath).listAll().then((res) => {
      return { isSuccess: true, data: res.items };
    }).catch(error => {
      return { isSuccess: false, error };
    });
    return res;
  };

  const editorUpload = async loader => {
    return loader.file.then(file => {
      return new Promise((res, rej) => {
        let uploadTask = storgeRef()
          .child(file.name)
          .put(file);
          
        uploadTask.on(
          'state_changed',
          function (snapshot) {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');

            switch (snapshot.state) {
              case 'paused': // or 'paused'
                console.log('Upload is paused');
                break;
              case 'running': // or 'running'
                console.log('Upload is running');
                break;
              default:
                break;
            }
          },
          function (error) {
            switch (error.code) {
              case 'storage/unauthorized':
                rej(' User doesn\'t have permission to access the object');
                break;

              case 'storage/canceled':
                rej('User canceled the upload');
                break;

              case 'storage/unknown':
                rej(
                  'Unknown error occurred, inspect error.serverResponse'
                );
                break;
              default:
                break;
            }
          },
          function () {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                res({
                  default: downloadURL
                });
              });
          }
        );
      });
    });
  };

  return { upload, deleteFile, getFileList, getDownloadURL, editorUpload };
};
