import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';




const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    flex: 1
  },
  container: {
    maxHeight: '600px',
  },
  th: {
    color: '#fff',
    backgroundColor: '#009be5',
    fontWeight: 'bold',
    fontSize: '1.4rem'
  },
  tr: {
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.08)'
    }
  },
  td: {
    '& > img': {
      width: '100px'
    },
    '& > * + *': {
      marginLeft: '5px'
    }
  }
});

export const StickyHeadTable = ({ data, total, columns, skip, limit, setSkip, setLimit, hasPagination = true }) => {
  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setSkip && setSkip(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit && setLimit(event.target.value);
    setSkip && setSkip(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className={classes.th}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id} className={classes.tr}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={classes.td}
                      >
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {hasPagination && <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={total}
        rowsPerPage={limit}
        page={skip}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="筆數"
      />}
    </Paper>
  );
};