import { createPortal } from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1200,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  main: {
    maxWidth: '700px',
    minWidth: '500px',
    borderRadius: '5px',
    backgroundColor: '#fff',
    overFlow: 'hidden',
    position: 'relative'
  },
  close: {
    color: '#fff',
    position: 'absolute',
    top: '2px',
    right: '5px',
    fontSize: '20px',
    cursor: 'pointer',
    transition: '0.35s',
    '&:hover': {
      transform: 'scale(1.2)'
    }
  },
  title: {
    backgroundColor: '#009be5',
    fontWeight: 'bold',
    fontSize: '1.4rem',
    padding: '10px',
    color: '#fff'
  },
  content: {
    padding: '20px 10px'
  },
  buttons: {
    padding: '20px 10px 10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: '20px'
    }
  }
});


export const Modal = ({ children, toggle, isShowing, title, confirm }) => {
  const classes = useStyles();

  const confirmHandler = () => {
    confirm();
    toggle();
  };

  const cancelHandler = () => {
    toggle();
  };

  return isShowing ? createPortal(
    <div className={classes.overlay}>
      <div className={classes.main}>
        <div className={classes.close} onClick={() => cancelHandler()}>✘</div>
        <div className={classes.title}>{title}</div>
        <div className={classes.content}>
          {children}
        </div>
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => cancelHandler()}>取消</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => confirmHandler()}>確認</Button>
        </div>
      </div>
    </div>, document.body
  ) : null;

};