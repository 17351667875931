import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
  label: {
    margin: '5px 0',
    textAlign: 'left'
  },
  root: {
    '& > h3': {
      margin: '3px 0'
    },
    '& .MuiTextField-root': {
      width: ({ width }) => width,
    }
  },
});

export const Input = ({
  value,
  type,
  onChange,
  label = '標題',
  placeholder = 'placeholder',
  name = 'name',
  width,
}) => {
  const classes = useStyles({ width });
  const onChnageHandler = e => onChange && onChange({ [name]: e.target.value });

  return (
    <div className={classes.root}>
      <h3 className={classes.label}>{label}</h3>
      <TextField
        value={value}
        variant="outlined"
        onChange={onChnageHandler}
        placeholder={placeholder}
        type={type || 'text'}
      />
    </div>
  );
};

Input.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
};