import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import defaultImage from 'assets/images/default.jpg';


/**
 * 上傳檔案的按鈕
 */

const defaultMessage = '請上傳課程圖檔';
const noop = () => { };

const MatTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 220,
    border: '1px solid #dadde9',
    lineHeight: 1.5,
  },
}))(Tooltip);

const useStyles = makeStyles({
  container: {
    padding: '10px 0'
  },
  image: {
    width: '400px',
    background: '#ccc',
    marginBottom: '10px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    objectFit: 'cover',
    fontSize: 0,
    '& > img': {
      width: '100%',
    }
  },
  uploadBox: {
    background: '#009be5',
    width: 'max-content',
    padding: '6px 16px',
    borderRadius: '5px',
    color: '#fff',
    cursor: 'pointer',
    '& > *': {
      cursor: 'pointer'
    }
  },
});

export const UploadButton = ({
  text = '上傳封面圖檔',
  message = defaultMessage,
  value = [],
  onChange = noop,
  limit = 1,
  src,
  ...props
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.image}>
        <img src={src} alt="" onError={(e) => { e.target.onerror = null; e.target.src = defaultImage; }} />
      </div>
      <MatTooltip title={message} placement="right" arrow={true}>
        <div className={classes.uploadBox}>
          <label htmlFor="upload">
            {text}
            <input
              style={{ display: 'none' }}
              {...props}
              id="upload"
              type="file"
              accept="image/*"
              onChange={e => onChange([...e.target.files].slice(limit - 1))}
            />
          </label>
          {Boolean(value.length) && <div>已上傳：{value.map(file => file.name).join(', ')}</div>}
        </div>
      </MatTooltip>
    </div>
  );
};

UploadButton.propTypes = {
  text: PropTypes.string,
  message: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,
  limit: PropTypes.number,
};


