import api from 'api';

export const getCourse = async params => {
  const { data } = await api.get('/course', params);
  return data;
};

export const createCourse = async payload => {
  const { data } = await api.post('/admin/course', payload);
  return data;
};

export const updateCourse = async (payload) => {
  const { data } = await api.put(`/admin/course/${payload.id}`, payload);
  return data;
};

export const getCourseById = async id => {
  const { data } = await api.get(`/course/${id}`);
  return data;
};

export const deleteCourse = async id => {
  const { data } = await api.delete(`/admin/course/${id}`);
  return data;
};